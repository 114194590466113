import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import TriDymeIcon from '../../media/TriDymeIconV1.png';
import TriDymeDefaultImage from '../../media/tridyme-default-image.png';


const SEO = props => (
  <StaticQuery
    query={detailsQuery}
    render={(data) => {
      const title = props.title || data.site.siteMetadata.title;
      const metaTitle = props.title || data.site.siteMetadata.title;
      const metaDescription = props.description || data.site.siteMetadata.description;
      const metaUrl = props.url || data.site.siteMetadata.url;
      const metaImage = props.image || TriDymeDefaultImage;
      const metaAuthor = props.author || data.site.siteMetadata.author;
      const language = props.lang || 'en';
      let metaKeywords = [];
      switch (language) {
        case 'fr':
          metaKeywords = [
            "btp", "marketplace", "calcul de structure", "Eurocode", "eurocode 2", "eurocode 3", "résistance des matériaux", "béton armé", "acier", "steel design", "concrete calculation", "reinforced concrete design", "construction software", "hydraulique", "geotechnique", "moment fléchissant", "génie civil", "saas", "bim", "calcul en ligne", "cloud"
          ]
          break;
        case 'en':
          metaKeywords = [
            "structural analysis", "Eurocode", "eurocode 2", "eurocode 3", "strength of material", "reinforced concrete", "steel", "steel design", "concrete calculation", "reinforced concrete design", "construction software", "hydraulic", "geotechnic", "bending moment", "civil engineering", "saas", "bim", "online calculation", "cloud"
          ]
          break;
        default:
          metaKeywords = [
            "structural analysis", "Eurocode", "eurocode 2", "eurocode 3", "strength of material", "reinforced concrete", "steel", "steel design", "concrete calculation", "reinforced concrete design", "construction software", "hydraulic", "geotechnic", "bending moment", "civil engineering", "saas", "bim", "online calculation", "cloud"
          ]
      }

      return (
        <Helmet
          htmlAttributes={{
            lang: props.lang,
          }}
          title={title}
          // titleTemplate={`%s - ${data.site.siteMetadata.title}`}
          link={[
            { rel: 'icon', type: 'image/png', href: `${TriDymeIcon}}` },
          ]}
          meta={[
            {
              name: 'google-site-verification',
              content: 'HnQ2mgnlHBbVKY-fTEP59rjZCdL-LqjrbCYp7Fy1eVo'
            },
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1'
            },
            {
              name: 'description',
              content: metaDescription
            },
            {
              property: 'og:title',
              content: metaTitle
            },
            {
              property: 'og:description',
              content: metaDescription
            },
            {
              property: 'og:type',
              content: 'website'
            },
            {
              property: 'og:image',
              content: metaImage
            },
            {
              property: 'og:url',
              content: metaUrl
            },
            {
              name: 'twitter:card',
              content: 'summary_large_image'
            },
            {
              name: 'twitter:creator',
              content: metaAuthor
            },
            {
              name: 'twitter:title',
              content: metaTitle
            },
            {
              name: 'twitter:description',
              content: metaDescription
            },
            {
              name: 'twitter:image',
              content: metaImage
            },
          ].concat(
            metaKeywords && metaKeywords.length > 0 ? {
              name: 'keywords',
              content: metaKeywords.join(', ')
            } : []
          )}
        />
      );
    }}
  />
);

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        url
        image
        author
      }
    }
  }
`;
