import React from 'react';
import SEO from '../SEO';

const HeadMeta = ({ locale, metaData }) => {
  return (
    <SEO 
      title={metaData[locale].title}
      lang={locale}
      description={metaData[locale].description}
    />
  );
};

export default HeadMeta;